// extracted by mini-css-extract-plugin
export const captureScroll = "CaptureCarousel-module--captureScroll--1BMup";
export const next = "CaptureCarousel-module--next--3rImq";
export const prev = "CaptureCarousel-module--prev--RcisS";
export const captureList = "CaptureCarousel-module--captureList--3Vh3I";
export const offset0 = "CaptureCarousel-module--offset0--Hbyeo";
export const offset1 = "CaptureCarousel-module--offset1--2BRPL";
export const offset2 = "CaptureCarousel-module--offset2--jIaaa";
export const offset3 = "CaptureCarousel-module--offset3--38aFf";
export const offset4 = "CaptureCarousel-module--offset4--fAcGF";
export const offset5 = "CaptureCarousel-module--offset5--jq8Gd";
export const offset6 = "CaptureCarousel-module--offset6--3DEQT";
export const offset7 = "CaptureCarousel-module--offset7--ob7Ht";
export const stack1 = "CaptureCarousel-module--stack1--1pnHW";
export const stack2 = "CaptureCarousel-module--stack2--1U0c6";
export const stack3 = "CaptureCarousel-module--stack3--vKHM5";
export const stack4 = "CaptureCarousel-module--stack4--1iq_U";
export const stack5 = "CaptureCarousel-module--stack5--2uBNc";
export const stack6 = "CaptureCarousel-module--stack6--1vUgX";
export const stack7 = "CaptureCarousel-module--stack7--3ZjJI";
export const stack8 = "CaptureCarousel-module--stack8--32eqN";
export const buttons = "CaptureCarousel-module--buttons--2TFX6";
export const active = "CaptureCarousel-module--active--sMKM_";